<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="500" overlay-opacity="0.5">
      <v-card>
        <!-- <v-card-title><v-icon large :color="filedata.file_icon[1]" class="pb-2 pr-2">{{ filedata.file_icon[0] }}</v-icon>{{filedata.file_name}}</v-card-title> -->
        <!-- <v-card-title
          >{{ $t("checkpassword.inputpassword") }} <v-spacer></v-spacer>
          <v-icon color="red" class="pb-2">lock</v-icon>
        </v-card-title>
        <v-divider></v-divider> -->
        <v-card-text style="height:200px">
          <br />
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card class="elevation-0">
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center mt-4">
                    <h2>{{$t("checkremember.check")}}</h2>
                  </v-flex>
                </v-layout>

                <v-layout row wrap justify-center>
                  <v-flex lg12 xs12 class="text-center">
                    <!-- <v-divider class="mt-6"></v-divider> -->
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg5 xs5 class="text-right mt-6">
                    <v-btn class="ma-2" color="red" outlined @click="closedialog()">{{$t("checkremember.no")}}</v-btn>
                  </v-flex>
                  <v-flex lg5 xs5 class="text-left mt-6 ml-2">
                    <v-btn class="ma-2" dark :color="color.theme" @click="fn_rememberme()">{{$t("checkremember.yes")}}</v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closedialog()" :disabled="processloading">{{ $t("checkpassword.cancel") }}</v-btn>
          <v-btn
            dark
            :color="color.theme"
            @click=""
            :loading="processloading"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      dialog: "",
      rememberme: true,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
  },
  props: ["show"],
  methods: {
    closedialog() {
      // localStorage.setItem("rememberme", false);
      this.$emit("closedialog");
      let token = VueCookies.get('token')
      sessionStorage.setItem("checklogin", false)
      sessionStorage.setItem("token", token)
      sessionStorage.setItem("token_auth", true)
      VueCookies.remove('token')
      VueCookies.remove('token_auth')
      
    },
    fn_rememberme() {
      // localStorage.setItem("rememberme", true);
      this.$emit("closedialog");
      VueCookies.set("checklogin", true, 60 * 60 * 24);
    },
  },
};
</script>
<style></style>
